import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Navigation from 'components/Navigation';
import { IconLogo } from 'components/Icon';
import { disableFocusOnItems } from 'utilities/disableItemsFocus';

class NavigationContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isScrolledToTop: true,
        };
    }

    componentDidMount() {
        this.handlePageScroll();
        window.addEventListener('scroll', this.handlePageScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handlePageScroll);
    }

    handlePageScroll = () => {
        const indentFromDocumentPageTop = 50;

        if (window.pageYOffset > indentFromDocumentPageTop) {
            this.setState({ isScrolledToTop: false });
        } else {
            this.setState({ isScrolledToTop: true });
        }
    };


    handleMenuToggle = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
            const { isOpen } = this.state;

            disableFocusOnItems(isOpen);
        });
    };

    render() {
        const { data, className, title } = this.props;
        const { isOpen, isScrolledToTop } = this.state;
        const { linkItem, itemsList } = data;
        const menuItems = itemsList.length > 0 ? itemsList.map((item, index) => ({
            id: `${index}`,
            title: item.section.linkTitle,
            link: `#${item.section.slug}`,
        })) : [];
        let callToActionItem;

        if (linkItem) {
            callToActionItem = {
                title: linkItem.text,
                link: linkItem.link,
            };
        }

        const renderNavigationLogo = (
            <a href="/" className="header__logo" data-mobile="hide">
                <IconLogo />
                <span className="visually-hidden">
                    <FormattedMessage id="SITE_NAME" />
                </span>
            </a>
        );

        const headerNavigationClass = classNames(className, {
            'header__navigation--open': isOpen,
            'header__navigation--scrolled': !isScrolledToTop,
        });

        return (
            <div className={headerNavigationClass}>
                <div className="container header__wrapper">
                    {renderNavigationLogo}
                    <Navigation
                        items={menuItems}
                        menuAlignment="center"
                        isMenuOpen={isOpen}
                        handleMenuToggle={this.handleMenuToggle}
                        isScrolledToTop={isScrolledToTop}
                        callToActionItem={callToActionItem}
                        title={title}
                    />
                </div>
            </div>
        );
    }
}

NavigationContent.propTypes = {
    data: PropTypes.shape({
        itemsList: PropTypes.arrayOf(
            PropTypes.shape({
                section: PropTypes.shape({
                    slug: PropTypes.string.isRequired,
                    linkTitle: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        ).isRequired,
        linkItem: PropTypes.shape({
            link: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    className: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default NavigationContent;
