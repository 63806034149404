import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from 'components/FieldLabel';
import FieldInput from 'components/FieldInput';
import FieldHint from 'components/FieldHint';

const FormField = ({
    ariaDescribedby,
    className,
    errorMessage,
    hideLabel,
    hintMessage,
    id,
    label,
    name,
    onChange,
    placeholder,
    type,
    value,
}) => {
    const formFieldClass = classNames('form__field', className);

    return (
        <div className={formFieldClass}>
            <FieldLabel id={`${id}-label`} htmlFor={id || name} label={label} isHidden={hideLabel}>
                <FieldInput
                    ariaDescribedby={ariaDescribedby || `${id}-hint`}
                    id={id}
                    errorMessage={errorMessage}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                />
            </FieldLabel>
            {hintMessage && <FieldHint id={`${id}-hint`} hint={hintMessage} />}
        </div>
    );
};

FormField.propTypes = {
    ariaDescribedby: PropTypes.string,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    hideLabel: PropTypes.bool,
    hintMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
};

FormField.defaultProps = {
    ariaDescribedby: '',
    className: '',
    errorMessage: '',
    hideLabel: false,
    hintMessage: '',
    onChange: () => {},
    placeholder: '',
    value: '',
};

export default FormField;
