import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/Button';
import FieldHint from 'components/FieldHint';
import FormField from 'components/FormField';
import Snackbar from 'components/Snackbar';
import { SnackbarVariants } from 'components/Snackbar/constants';
import { isEmailValid } from 'utilities/validators/emailValidator';
import loadGoogleRecaptcha from 'utilities/loadGoogleRecaptcha';

class SignUpForm extends Component {
    state = {
        auth: '',
        email: '',
        emailErrorMessage: '',
        inputErrorMessage: '',
        showAlert: false,
        clientVerified: false,
        alertType: SnackbarVariants.DEFAULT,
        loading: false,
    };


    componentDidMount() {
        const { clientVerified } = this.state;
        if (!clientVerified) {
            loadGoogleRecaptcha(() => {
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(process.env.GATSBY_GOOGLE_RECAPTCHAV3_SITE_KEY, { action: 'subscribeNewsletter' })
                        .then((token) => {
                            this.handleRecaptchaVerify(token);
                        });
                });
            });
        }
    }

    handleAuthChange = (event) => {
        this.setState({ auth: event.target.value });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    };

    setInvalidFormatState = () => {
        const { intl } = this.props;
        const { formatMessage } = intl;

        this.setState({
            showAlert: false,
            inputErrorMessage: formatMessage({ id: 'SUBSCRIBE_FORM_EMAIL_INCORRECT_FORMAT' }),
            loading: false,
        });
    };

    setInvalidEmailState = (validationResult) => {
        const { intl } = this.props;
        const { formatMessage } = intl;
        let errorMessage;

        if (validationResult.suggestion && validationResult.host !== validationResult.suggestion) {
            const recipient = validationResult.email.substring(0, validationResult.email.indexOf('@'));
            errorMessage = formatMessage({
                id: 'SUBSCRIBE_FORM_EMAIL_INCORRECT_WITH_SUGGESTION',
            }, {
                recipient,
                suggestion: validationResult.suggestion,
            });
        } else {
            errorMessage = formatMessage({ id: 'SUBSCRIBE_FORM_EMAIL_INVALID' });
        }

        this.setState({
            showAlert: false,
            inputErrorMessage: errorMessage,
            loading: false,
        });
    };

    setInvalidClientState = () => {
        const errorMessage = 'It looks you are a bot. Please try later.';

        this.setState({
            showAlert: true,
            alertType: SnackbarVariants.ERROR,
            emailErrorMessage: errorMessage,
            inputErrorMessage: '',
            loading: false,
        });
    };

    setErrorState = () => {
        const { intl } = this.props;
        const { formatMessage } = intl;
        const errorMessage = formatMessage({ id: 'SUBSCRIBE_FORM_ALERT_ERROR_MESSAGE' });

        this.setState({
            showAlert: true,
            alertType: SnackbarVariants.ERROR,
            emailErrorMessage: errorMessage,
            inputErrorMessage: '',
            loading: false,
        });
    };

    setSuccessState = () => {
        const { intl } = this.props;
        const { formatMessage } = intl;
        const successMessage = formatMessage({ id: 'SUBSCRIBE_FORM_ALERT_SUCCESS_MESSAGE' });

        this.setState({
            email: '',
            showAlert: true,
            alertType: SnackbarVariants.DEFAULT,
            emailErrorMessage: successMessage,
            inputErrorMessage: '',
            loading: false,
        });
    };

    setRecaptchaResult = (score) => {
        const minimalScoreValue = parseFloat(process.env.GATSBY_GOOGLE_RECAPTCHAV3_MIN_SCORE);
        const scoreNumber = parseFloat(score);

        if (scoreNumber >= minimalScoreValue) {
            this.setState({ clientVerified: true });
        }
    };

    saveEmail = () => {
        const siteUrl = '/.netlify/functions/addContact';
        const { email } = this.state;

        const content = JSON.stringify({
            list_ids: [process.env.GATSBY_SENDGRID_CONTACTS_LIST_ID],
            contacts: [{
                email,
            }],
        });

        fetch(siteUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: content,
        })
            .then((response) => {
                if (response.ok) {
                    this.setSuccessState();
                } else {
                    this.setErrorState();
                }
            });
    };

    validateEmailOnServer = (callback) => {
        const validateUrl = '/.netlify/functions/validateEmail';
        const source = 'US Recruitment Site';
        const { email } = this.state;
        const validationContent = JSON.stringify({
            email,
            source,
        });

        fetch(validateUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: validationContent,
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                throw new Error('Request failed.');
            })
            .then((data) => {
                if (data && !data.result.suggestion && data.result.score >= 0.20) {
                    callback();
                } else {
                    this.setInvalidEmailState(data.result);
                }
            })
            .catch(() => {
                this.setErrorState();
            });
    };

    validateSecurity = () => {
        const { auth, clientVerified } = this.state;

        return auth.length === 0 && clientVerified;
    };

    validateForm = () => {
        const { email } = this.state;

        return isEmailValid(email);
    };

    handleSnackbarClose = () => {
        this.setState({ showAlert: false });
    };

    handleRecaptchaVerify = (token) => {
        const validateUrl = '/.netlify/functions/verifyRecaptcha';

        fetch(validateUrl, {
            method: 'POST',
            body: token,
        })
            .then(res => res.json())
            .then((data) => {
                if (data.success) {
                    this.setRecaptchaResult(data.score);
                } else {
                    throw new Error('Request failed.');
                }
            })
            .catch((err) => {
                this.setErrorState();
            });
    };

    /* eslint-disable-next-line consistent-return */
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });

        if (this.validateSecurity()) {
            if (this.validateForm()) {
                this.validateEmailOnServer(this.saveEmail);
            } else {
                this.setInvalidFormatState();
            }
        } else {
            this.setInvalidClientState();
        }
    };

    render() {
        const {
            auth, inputErrorMessage, email, emailErrorMessage, showAlert, alertType, loading,
        } = this.state;

        const { intl, inputId, gtmClassName } = this.props;
        const { formatMessage } = intl;

        const formClassName = classNames('form form--contact-us', gtmClassName);

        return (
            <form className={formClassName} onSubmit={this.handleSubmit}>
                <div className="form__combined">
                    <input type="hidden" name="auth" value={auth} onChange={this.handleAuthChange} />
                    <FormField
                        id={inputId}
                        label={formatMessage({ id: 'SUBSCRIBE_FORM_FORM_FIELD_EMAIL_LABEL' })}
                        placeholder={formatMessage({ id: 'SUBSCRIBE_FORM_FIELD_EMAIL_PLACEHOLDER' })}
                        hideLabel
                        errorMessage={inputErrorMessage}
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.handleEmailChange}
                    />
                    <Button
                        type="submit"
                        variant="primary"
                        className="form__submit button--with-loader"
                        loading={loading}
                    >
                        <FormattedMessage id="SUBSCRIBE_FORM_SUBMIT" />
                    </Button>
                </div>
                <FieldHint id={`${inputId}-hint`} hint={<FormattedMessage id="SUBSCRIBE_FORM_HINT" />} />
                {showAlert && (
                    <Snackbar
                        message={emailErrorMessage}
                        variant={alertType}
                        onClick={this.handleSnackbarClose}
                    />
                )}
            </form>
        );
    }
}

SignUpForm.propTypes = {
    intl: PropTypes.object.isRequired,
    inputId: PropTypes.string.isRequired,
    gtmClassName: PropTypes.string,
};

SignUpForm.defaultProps = {
    gtmClassName: '',
};

export default injectIntl(SignUpForm);
