import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Section from 'components/Section';
import {
    SectionVariants, SectionEdgePosition, SectionContentAlignment, SectionEdgeGap,
} from 'components/Section/constants';
import { CardVariants } from 'components/Card/constants';
import { IconChat, IconEducation, IconGlobal } from 'components/Icon';
import { getFormattedSectionArticles } from 'utilities/getSectionArticles';

const ValuePropositionSectionContent = ({ data }) => {
    const {
        textItems, subsections
    } = data.section;
    const articles = getFormattedSectionArticles(subsections);
    const icons = [
        <IconChat />,
        <IconEducation />,
        <IconGlobal />,
    ];

    const articlesWithIcons = articles.map((article, index) => ({
        ...article,
        icon: icons[index] || null,
    }));

    return (
        <Section
            id="value-proposition"
            animate
            title={textItems.headerText.headerText}
            subtitle={textItems.subheaderText}
            articles={articlesWithIcons}
            cardVariant={CardVariants.STANDARD}
            contentAlignment={SectionContentAlignment.LEFT}
            hasEdge
            edgeGap={SectionEdgeGap.NARROW}
            edgePosition={SectionEdgePosition.LEFT}
            columns={articlesWithIcons.length}
            contentType="article"
            variant={SectionVariants.DEFAULT}
        />
    );
};

ValuePropositionSectionContent.propTypes = {
    data: PropTypes.shape({
        section: PropTypes.shape({
            textItems: PropTypes.shape({
                headerText: PropTypes.shape({
                    headerText: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            subsections: PropTypes.arrayOf(
                PropTypes.shape({
                    textItems: PropTypes.shape({
                        headerText: PropTypes.shape({
                            headerText: PropTypes.string.isRequired,
                        }).isRequired,
                        contentText: PropTypes.object.isRequired,
                    }).isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
    }).isRequired,
};

const ValuePropositionSection = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulValuePropositionSection {
                    nodes{
                        id
                        section {
                            textItems {
                                headerText {
                                    headerText
                                }
                                subheaderText
                            }
                            subsections {
                                textItems {
                                    headerText {
                                        headerText
                                    }
                                    contentText {
                                        raw
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ allContentfulValuePropositionSection }) => {
            const item = allContentfulValuePropositionSection.nodes.find(
                node => node.id === props.id,
            );
            return (
                <ValuePropositionSectionContent data={item} />
            );
        }}
    />
);

ValuePropositionSection.propTypes = {
    id: PropTypes.string.isRequired,
};

export default ValuePropositionSection;
