import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import siteLanguage from 'utilities/locale';

const PageHead = ({ title, description, pathname }) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata: {
                    defaultTitle,
                    titleTemplate,
                    defaultDescription,
                    siteUrl,
                    orgName,
                    orgSite,
                    facebookLink,
                    twitterLink,
                    twitterUsername,
                    linkedinLink,
                    pinterestLink,
                    blogLink,
                    soundcloudLink,
                },
            },
            contentfulHeroSection: {
                section: {
                    image: {
                        localFile: {
                            childImageSharp: { gatsbyImageData },
                        },
                    },
                },
            },
        }) => {
            var gatsbyImage = getImage(gatsbyImageData);
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDescription,
                image: `${siteUrl}${gatsbyImage.images.fallback.src || null}`,
                imageWidth: gatsbyImage.width || 0,
                imageHeight: gatsbyImage.height || 0,
                url: `${siteUrl}${pathname || '/'}`,
            };

            return (
                <Helmet
                    title={seo.title}
                    titleTemplate={titleTemplate}
                    defer={false}
                >
                    <html lang={siteLanguage} />
                    <meta name="description" content={seo.description} />
                    <meta name="og:type" content="website" />
                    {seo.title && (
                        <meta property="og:title" content={seo.title} />
                    )}
                    {seo.title && <meta itemProp="name" content={seo.title} />}
                    {seo.description && (
                        <meta
                            property="og:description"
                            content={seo.description}
                        />
                    )}
                    {seo.description && (
                        <meta
                            itemProp="description"
                            content={seo.description}
                        />
                    )}
                    {seo.url && <meta property="og:url" content={seo.url} />}
                    {seo.image && (
                        <meta property="og:image" content={seo.image} />
                    )}
                    {seo.image && <meta itemProp="image" content={seo.image} />}
                    {seo.imageWidth && (
                        <meta
                            property="og:image:width"
                            content={seo.imageWidth}
                        />
                    )}
                    {seo.imageHeight && (
                        <meta
                            property="og:image:height"
                            content={seo.imageHeight}
                        />
                    )}
                    <meta name="twitter:card" content="summary_large_image" />
                    {twitterUsername && (
                        <meta
                            name="twitter:creator"
                            content={twitterUsername}
                        />
                    )}
                    {seo.title && (
                        <meta name="twitter:title" content={seo.title} />
                    )}
                    {seo.description && (
                        <meta
                            name="twitter:description"
                            content={seo.description}
                        />
                    )}
                    {seo.image && (
                        <meta name="twitter:image" content={seo.image} />
                    )}
                    <link rel="canonical" href="https://join.mdrt.org" />
                    <script type="application/ld+json">
                        {`
                            {
                                "@context": "https://schema.org",
                                "@type": "WebSite",
                                "name": "${seo.title}",
                                "description": "${seo.description}",
                                "url": "${seo.url}",
                                "publisher": {
                                    "@type": "Organization",
                                    "name": "${orgName}",
                                    "url": "${orgSite}",
                                    "sameAs": [
                                        "${facebookLink}",
                                        "${twitterLink}",
                                        "${linkedinLink}",
                                        "${pinterestLink}",
                                        "${blogLink}",
                                        "${soundcloudLink}"
                                    ]
                                },
                                "image": {
                                    "@type": "ImageObject",
                                    "url": "${seo.image}",
                                    "caption": "${seo.title}"
                                }
                            }
                        `}
                    </script>
                </Helmet>
            );
        }}
    />
);

PageHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    pathname: PropTypes.string,
};

PageHead.defaultProps = {
    title: '',
    description: '',
    pathname: '',
};

export default PageHead;

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                orgName
                orgSite
                facebookLink
                twitterLink
                twitterUsername
                linkedinLink
                pinterestLink
                blogLink
                soundcloudLink
            }
        }
        contentfulHeroSection {
            section {
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FIXED, width: 1200)
                        }
                    }
                }
            }
        }
    }
`;
