import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';

import Section from 'components/Section';
import {
    SectionVariants,
    SectionEdgePosition,
} from 'components/Section/constants';
import { CardVariants } from 'components/Card/constants';
import { extractPlainTextFromParagraph } from 'utilities/richTextHandlers';

const SectionContent = ({ data }) => {
    const { headerText, testimonialsList } = data;
    const formattedTestimonials = [];

    const getFormattedTestimonialsList = () => {
        if (testimonialsList.length > 0) {
            return testimonialsList.map((item) => {
                const { authorName, authorTitle, authorImage } = item;

                let gatsbyImage;
                if (authorImage.defaultImage) {
                    gatsbyImage = getImage(authorImage.defaultImage);
                }

                const content = extractPlainTextFromParagraph(item.itemText);
                formattedTestimonials.push({
                    content: content,
                    author: authorName,
                    position: authorTitle || null,
                    gatsbyImage: gatsbyImage || null,
                });

                return null;
            });
        }
        return null;
    };

    getFormattedTestimonialsList();

    const columns = formattedTestimonials.length > 1 ? 2 : 1;

    return (
        <Section
            animate
            id="testimonials"
            title={headerText}
            articles={formattedTestimonials}
            variant={SectionVariants.DEFAULT}
            cardVariant={CardVariants.TESTIMONIAL}
            columns={columns}
            contentType="blockquote"
            hasEdge
            edgePosition={SectionEdgePosition.LEFT}
            isTrackableAsPage
        />
    );
};

const TestimonialsSection = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulTestimonialsSection {
                    nodes {
                        id
                        headerText
                        testimonialsList {
                            itemText {
                                raw
                            }
                            authorName
                            authorTitle
                            authorImage {
                                defaultImage: localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={({ allContentfulTestimonialsSection }) => {
            const item = allContentfulTestimonialsSection.nodes.find(
                (node) => node.id === props.id
            );
            return <SectionContent data={item} />;
        }}
    />
);

TestimonialsSection.propTypes = {
    id: PropTypes.string.isRequired,
};

SectionContent.propTypes = {
    data: PropTypes.shape({
        headerText: PropTypes.string.isRequired,
        testimonialsList: PropTypes.arrayOf(
            PropTypes.shape({
                itemText: PropTypes.object.isRequired,
            }).isRequired
        ).isRequired,
    }).isRequired,
};

export default TestimonialsSection;
