import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
    IconAddress, IconMail, IconPhone, IconLogo, IconFacebook, IconLinkedin, IconPinterest, IconTwitter,
} from 'components/Icon';
import SignUpForm from '../SignUpForm';

const FooterContent = ({ data }) => {
    const {
        address, email, phone, socialLinks, copyright,
    } = data;
    const socialIcons = [
        <IconFacebook height={25} width={25} />,
        <IconTwitter height={25} width={25} />,
        <IconLinkedin height={25} width={25} />,
        <IconPinterest height={25} width={25} />,
    ];

    /* eslint-disable react/no-array-index-key */
    const socialLinksWithIcons = socialLinks.map((item, index) => ({
        ...item,
        icon: socialIcons[index] || null,
    }));
    /* eslint-enable */

    const contactInfoWithIcons = [
        {
            name: <FormattedMessage id="FOOTER_CONTACT_PHONE" />,
            content: phone.itemText,
            link: `tel:${phone.itemText}`,
            icon: <IconPhone height={22} width={22} />,
        },
        {
            name: <FormattedMessage id="FOOTER_CONTACT_EMAIL" />,
            content: email.itemText,
            link: `mailto:${email.itemText}`,
            icon: <IconMail height={22} width={22} />,
        },
        {
            name: <FormattedMessage id="FOOTER_CONTACT_ADDRESS" />,
            content: address.itemText,
            icon: <IconAddress height={22} width={22} />,
        },
    ];

    const renderSocialMediaSection = () => (
        <div className="footer__section footer__section--social-media">
            <span className="footer__logo">
                <IconLogo />
            </span>
            <h2 className="footer__section-title visually-hidden">
                <FormattedMessage id="FOOTER_SOCIAL_MEDIA_SECTION_TITLE" />
            </h2>
            <div className="footer__section-content">
                <ul className="footer__social-icons">
                    {socialLinksWithIcons.length > 0 && socialLinksWithIcons.map((item) => {
                        if (item.link) {
                            return (
                                <li key={item.link} className="footer__social-icon">
                                    <a href={item.link} target="_blank" rel="noopener noreferrer" className="social-link">
                                        {!!item.icon && item.icon}
                                        <span className="visually-hidden">{item.title}</span>
                                    </a>
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
                <p className="footer__copyright">{copyright}</p>
            </div>
        </div>
    );

    const renderContactSection = () => (
        <div className="footer__section footer__section--contact-us">
            <h2 className="footer__section-title visually-hidden">
                <FormattedMessage id="FOOTER_CONTACTS_SECTION_TITLE" />
            </h2>
            <div className="footer__section-content">
                <address className="footer__contacts">
                    {contactInfoWithIcons.map((item, index) => (
                        <div key={item.id ? item.id : index} className="contact-row">
                            <div className="contact-row__icon">
                                {!!item.icon && item.icon}
                                {!!item.name && <span className="visually-hidden">{item.name}</span>}
                            </div>
                            <div className="contact-row__description">
                                {item.link ? <a href={item.link} className="link link--match-content">{item.content}</a> : item.content}
                            </div>
                        </div>
                    ))}
                </address>
            </div>
        </div>
    );

    return (
        <footer role="contentinfo" className="footer" data-mobile="hide">
            <div className="section section--footer section--with-edge section--with-edge-left">
                <div className="container">
                    <div className="footer__sections-wrapper">
                        {renderSocialMediaSection()}
                        {renderContactSection()}
                        <div className="footer__section footer__section--subscribe">
                            <h2 className="footer__section-title">
                                <FormattedMessage id="FOOTER_SUBSCRIPTION_SECTION_TITLE" />
                            </h2>
                            <SignUpForm inputId="footer-email" gtmClassName="subscribe-footer" />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

FooterContent.propTypes = {
    data: PropTypes.shape({
        phone: PropTypes.shape({
            itemText: PropTypes.string.isRequired,
        }),
        address: PropTypes.shape({
            itemText: PropTypes.string.isRequired,
        }),
        email: PropTypes.shape({
            itemText: PropTypes.string.isRequired,
        }),
        socialLinks: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        copyright: PropTypes.string.isRequired,
    }).isRequired,
};

export default FooterContent;
