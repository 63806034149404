import { getImage } from 'gatsby-plugin-image';
import { renderReactComponents } from 'utilities/richTextHandlers';

export const getFormattedSectionArticles = (articles) => {
    if (articles.length > 0) {
        const formattedArticles = [];

        articles.map((item) => {
            const itemTitle = item.textItems.headerText.headerText;
            let gatsbyImage;
            if (item.image) {
                gatsbyImage = getImage(item.image.defaultImage);
            }
            const imageAltText = item.imageDescription;

            formattedArticles.push({
                content:
                    renderReactComponents(item.textItems.contentText) || null,
                gatsbyImage: gatsbyImage || null,
                imageAltText: imageAltText || '',
                title: itemTitle || null,
            });

            return null;
        });

        return formattedArticles;
    }
    return null;
};

export default getFormattedSectionArticles;
