export const SectionVariants = {
    CALL_TO_ACTION: 'call-to-action',
    DEFAULT: 'default',
    HIGHLIGHTED: 'highlighted',
};

export const SectionColumns = {
    NONE: null,
    TWO: 2,
    THREE: 3,
};

export const SectionEdgePosition = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const SectionContentAlignment = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const SectionEdgeGap = {
    NARROW: 'narrow',
    NORMAL: 'normal',
};
