import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';

import { extractPlainTextFromParagraph } from 'utilities/richTextHandlers';

import Banner from 'components/Banner';

const HeroBannerContent = ({ data }) => {
    if (!data) {
        return null;
    }

    const { textItems, image, imageTextColor, imageTextSize } = data.section;
    const bannerImage = getImage(image.defaultImage);

    return (
        <Banner
            animate
            id="hero-banner"
            image={bannerImage}
            title={textItems.headerText.headerText}
            subtitle={extractPlainTextFromParagraph(textItems.contentText)}
            textColor={imageTextColor}
            textSize={imageTextSize}
        />
    );
};

HeroBannerContent.propTypes = {
    data: PropTypes.shape({
        section: PropTypes.shape({
            imageTextColor: PropTypes.string,
            imageTextSize: PropTypes.string,
            textItems: PropTypes.shape({
                headerText: PropTypes.shape({
                    headerText: PropTypes.string.isRequired,
                }).isRequired,
                contentText: PropTypes.object.isRequired,
            }).isRequired,
            linkItem: PropTypes.shape({
                text: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }).isRequired,
            image: PropTypes.shape({
                defaultImage: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default HeroBannerContent;
