import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import NavigationContent from './HeaderNavigationContent';

const HeaderNavigation = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulNavigationMenu {
                    nodes {
                        id
                        itemsList {
                            ... on ContentfulEducationalResourcesSection {
                                section {
                                    slug
                                    linkTitle
                                }
                            }
                            ... on ContentfulGlobalNetworkSection {
                                section {
                                    slug
                                    linkTitle
                                }
                            }
                            ... on ContentfulMemberOnlyMeetingsSection {
                                section {
                                    slug
                                    linkTitle
                                }
                            }
                            ... on ContentfulMemberRequirements {
                                section {
                                    slug
                                    linkTitle
                                }
                            }
                        }
                        linkItem {
                            text
                            link
                        }
                    }
                }
            }
        `}
        render={({ allContentfulNavigationMenu }) => {
            const item = allContentfulNavigationMenu.nodes.find(
                node => node.id === props.id,
            );
            return (
                <NavigationContent data={item} className={props.className} title={props.title} />
            );
        }}
    />
);

HeaderNavigation.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};

HeaderNavigation.defaultProps = {
    className: '',
    title: '',
};

export default HeaderNavigation;
