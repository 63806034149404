import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import SubscribeSectionContent from './SubscribeSectionContent';

const SubscribeSection = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulSubscribeSection {
                    nodes {
                        id
                        section {
                            slug
                            textItems {
                                headerText {
                                    headerText
                                }
                                subheaderText
                            }
                        }
                    }
                }
            }
        `}
        render={({ allContentfulSubscribeSection }) => {
            const item = allContentfulSubscribeSection.nodes.find(
                node => node.id === props.id,
            );
            return <SubscribeSectionContent data={item} />;
        }}
    />
);

SubscribeSection.propTypes = {
    id: PropTypes.string.isRequired,
};

export default SubscribeSection;
