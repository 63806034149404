import 'wicg-inert';

export const disableFocusOnItems = (value) => {
    const focusIgnoreAttribute = '[data-mobile="hide"]';
    const disableItemsFocus = [...document.querySelectorAll(focusIgnoreAttribute)];

    /* eslint-disable no-return-assign, no-param-reassign */
    disableItemsFocus.forEach(item => item.inert = value);
    /* eslint-enable */
};

export default disableFocusOnItems;
