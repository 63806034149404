import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SnackbarVariants } from 'components/Snackbar/constants';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/constants';

const Snackbar = ({
    className, message, variant, onClick,
}) => {
    const snackbarClass = classNames('snackbar', {
        [`snackbar--${variant}`]: true,
    }, className);

    return (
        <div className={snackbarClass} role="alert" aria-live="polite">
            <p className="snackbar__message">{message}</p>
            <Button type="button" variant={ButtonVariants.TRANSPARENT} className="snackbar__button" onClick={onClick}>
                <FormattedMessage id="SNACKBAR_CLOSE" />
            </Button>
        </div>
    );
};

Snackbar.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    variant: PropTypes.oneOf(Object.values(SnackbarVariants)),
    onClick: PropTypes.func.isRequired,
};

Snackbar.defaultProps = {
    className: '',
    variant: SnackbarVariants.DEFAULT,
};

export default Snackbar;
