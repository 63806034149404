import PropTypes from 'prop-types';

const navigationItemsProptypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            isCallToAction: PropTypes.bool,
        }),
    ).isRequired,
};

export const navigationListItemsProptypes = navigationItemsProptypes;

export const navigationProptypes = {
    ...navigationItemsProptypes,
    menuAlignment: PropTypes.oneOf([
        '',
        'right',
        'center',
    ]),
};

export const navigationDefaultProps = {
    ...navigationItemsProptypes,
    menuAlignment: '',
};
