export const loadGoogleRecaptcha = (callback) => {
    const loadedScript = document.getElementById('google-recaptcha-v3');

    if (!loadedScript) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_GOOGLE_RECAPTCHAV3_SITE_KEY}`;
        script.id = 'google-recaptcha-v3';
        script.type = 'application/javascript';
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }

    if (loadedScript && callback) {
        loadedScript.onload = () => callback();
    }
};

export default loadGoogleRecaptcha;
