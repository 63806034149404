import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import Section from 'components/Section';
import { SectionVariants, SectionContentAlignment, SectionEdgePosition } from 'components/Section/constants';
import { CardVariants } from 'components/Card/constants';
import { getFormattedSectionArticles } from 'utilities/getSectionArticles';
import { IconChart, IconMoney, IconTrophy } from 'components/Icon';
import { extractPlainTextFromParagraph } from 'utilities/richTextHandlers';

const SectionContent = ({ data }) => {
    const {
        textItems, subsections, slug, linkItem,
    } = data.section;
    const contentText = extractPlainTextFromParagraph(textItems.contentText);
    const articles = getFormattedSectionArticles(subsections);
    const icons = [
        <IconChart />,
        <IconTrophy />,
        <IconMoney />,
    ];

    const articlesWithIcons = articles.map((article, index) => ({
        ...article,
        icon: icons[index] || null,
    }));

    return (
        <Section
            animate
            id={slug}
            title={textItems.headerText.headerText}
            subtitle={contentText}
            articles={articlesWithIcons}
            variant={SectionVariants.HIGHLIGHTED}
            cardVariant={CardVariants.STANDARD}
            callToActionButtonText={linkItem.text}
            callToActionUrl={linkItem.link}
            columns={3}
            contentAlignment={SectionContentAlignment.CENTER}
            contentType="article"
            hasEdge
            edgePosition={SectionEdgePosition.RIGHT}
            isNavigationItem
            isTrackableAsPage
        />
    );
};

const MemberRequirementsSection = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulMemberRequirements {
                    nodes {
                        id
                        section {
                            slug
                            textItems {
                                headerText {
                                    headerText
                                }
                                contentText {
                                    raw
                                }
                            }
                            linkItem {
                                text
                                link
                            }
                            subsections {
                                textItems {
                                    headerText {
                                        headerText
                                    }
                                    contentText {
                                        raw
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const item = data.allContentfulMemberRequirements.nodes.find(
                node => node.id === props.id,
            );
            return (
                <SectionContent data={item} />
            );
        }}
    />
);

MemberRequirementsSection.propTypes = {
    id: PropTypes.string.isRequired,
};

SectionContent.propTypes = {
    data: PropTypes.shape({
        section: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            textItems: PropTypes.shape({
                headerText: PropTypes.shape({
                    headerText: PropTypes.string.isRequired,
                }).isRequired,
                contentText: PropTypes.object.isRequired,
            }).isRequired,
            linkItem: PropTypes.shape({
                text: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }).isRequired,
            subsections: PropTypes.arrayOf(
                PropTypes.shape({
                    textItems: PropTypes.shape({
                        headerText: PropTypes.shape({
                            headerText: PropTypes.string.isRequired,
                        }).isRequired,
                        contentText: PropTypes.object.isRequired,
                    }).isRequired,
                }).isRequired,
            ).isRequired,
        }).isRequired,
    }).isRequired,
};

export default MemberRequirementsSection;
