import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isBrowser from 'utilities/isBrowser';
import ApplyUtmParametersToUrl from 'utilities/applyUtmParams';

const NavigationCallToActionButton = ({
    link,
    title,
    onKeyDown,
    refToPass,
}) => {
    const [href, setHref] = useState(link);
    useEffect(() => {
        if (link) {
            const newHref = isBrowser ? ApplyUtmParametersToUrl(link) : link;
            setHref(newHref);
        }
    }, [setHref, link]);

    return (
        <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="navigation__call-to-action"
            ref={refToPass}
            onKeyDown={onKeyDown}
        >
            {title}
        </a>
    );
};

NavigationCallToActionButton.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    refToPass: PropTypes.func.isRequired,
};

export default NavigationCallToActionButton;
