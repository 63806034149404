import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/constants';
import Card from 'components/Card';
import List from 'components/List';
import { CardVariants } from 'components/Card/constants';
import {
    SectionColumns,
    SectionContentAlignment,
    SectionEdgeGap,
    SectionEdgePosition,
    SectionVariants,
} from './constants';

const Section = ({
    animate,
    articles,
    callToActionButtonText,
    callToActionUrl,
    cardVariant,
    children,
    className,
    columns,
    content,
    contentAlignment,
    contentType,
    edgeGap,
    edgePosition,
    hasEdge,
    id,
    isNavigationItem,
    isTrackableAsPage,
    observeSection,
    subtitle,
    title,
    showList,
    listTitle,
    listText,
    listItems,
    variant,
}) => {
    const sectionClass = classNames(
        'section',
        {
            [`section--${variant}`]: true,
            'section--with-edge': hasEdge,
            'section--animate': animate,
            [`section--with-edge-${edgePosition}`]: hasEdge,
            [`section--align-${contentAlignment}`]: true,
        },
        className
    );

    const sectionColumnsClass = classNames('section__columns', {
        'section__columns--two': columns === SectionColumns.TWO,
        'section__columns--three': columns === SectionColumns.THREE,
    });

    const sectionWrapperClass = classNames('section__wrapper', 'container', {
        [`section__wrapper--edge-gap-${edgeGap}`]: true,
    });

    return (
        <section
            id={id}
            className={sectionClass}
            data-animate={animate}
            data-navigation-item={isNavigationItem}
            data-track-as-page={isTrackableAsPage}
            data-observe={observeSection}
        >
            <div className={sectionWrapperClass}>
                {!!title && (
                    <h2 className="section__title">
                        <ReactMarkdown children={title} />
                    </h2>
                )}
                {!!subtitle && <p className="section__subtitle">{subtitle}</p>}
                {!!content && (
                    <span className="section__content">{content}</span>
                )}
                {showList && (
                    <List
                        theme="dark"
                        listTitle={listTitle}
                        listText={listText}
                        listItems={listItems}
                    />
                )}
                {articles.length > 0 && (
                    <div className={sectionColumnsClass}>
                        {articles.map((article, index) => (
                            <div
                                key={article.id ? article.id : index}
                                className="section__column"
                            >
                                <Card
                                    author={article.author}
                                    containerElement={contentType}
                                    content={article.content}
                                    gatsbyImage={article.gatsbyImage}
                                    imageAltText={article.imageAltText}
                                    icon={article.icon}
                                    position={article.position}
                                    title={article.title}
                                    variant={cardVariant}
                                />
                            </div>
                        ))}
                    </div>
                )}
                {!!children && children}
                {!!callToActionUrl && (
                    <div className="section__actions-wrapper">
                        <Button
                            className="section__action-button"
                            displayBlockOnMobile
                            href={callToActionUrl}
                            variant={ButtonVariants.PRIMARY}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {callToActionButtonText}
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
};

Section.propTypes = {
    animate: PropTypes.bool,
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            author: PropTypes.string,
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            gatsbyImage: PropTypes.object,
            imageAltText: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    callToActionButtonText: PropTypes.string,
    callToActionUrl: PropTypes.string,
    cardVariant: PropTypes.oneOf(Object.values(CardVariants)),
    children: PropTypes.node,
    className: PropTypes.string,
    columns: PropTypes.oneOf(Object.values(SectionColumns)),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    contentAlignment: PropTypes.oneOf(Object.values(SectionContentAlignment)),
    contentType: PropTypes.string,
    edgeGap: PropTypes.oneOf(Object.values(SectionEdgeGap)),
    edgePosition: PropTypes.oneOf(Object.values(SectionEdgePosition)),
    hasEdge: PropTypes.bool,
    id: PropTypes.string,
    isNavigationItem: PropTypes.bool,
    isTrackableAsPage: PropTypes.bool,
    observeSection: PropTypes.bool,
    subtitle: PropTypes.string,
    showList: PropTypes.bool,
    title: PropTypes.string,
    listTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    listText: PropTypes.string,
    listItems: PropTypes.arrayOf(PropTypes.string),
    variant: PropTypes.oneOf(Object.values(SectionVariants)),
};

Section.defaultProps = {
    animate: false,
    articles: [],
    callToActionButtonText: '',
    callToActionUrl: '',
    cardVariant: CardVariants.STANDARD,
    className: '',
    children: null,
    columns: SectionColumns.NONE,
    content: '',
    contentAlignment: SectionContentAlignment.LEFT,
    contentType: '',
    edgeGap: SectionEdgeGap.NORMAL,
    edgePosition: SectionEdgePosition.LEFT,
    hasEdge: false,
    id: '',
    isNavigationItem: false,
    isTrackableAsPage: false,
    observeSection: true,
    subtitle: '',
    showList: false,
    title: '',
    listTitle: '',
    listText: '',
    listItems: [],
    variant: SectionVariants.DEFAULT,
};

export default Section;
