import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldLabel = ({
    className,
    children,
    isHidden,
    label,
    ...other
}) => {
    const labelClass = classNames('field-label', {
        'field-label--hidden': isHidden,
    }, className);

    /* eslint-disable */
    return (
        <label className={labelClass} {...other}>
            <span className="field-label__text">{label}</span>
            {children}
        </label>
    );
    /* eslint-enable */
};

FieldLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isHidden: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

FieldLabel.defaultProps = {
    children: null,
    className: '',
    isHidden: false,
};

export default FieldLabel;
