export const ContentfulContentSections = {
    NAVIGATION_MENU: 'ContentfulNavigationMenu',
    HERO: 'ContentfulHeroSection',
    VALUE_PROPOSITION: 'ContentfulValuePropositionSection',
    MEMBER_ONLY_MEETINGS: 'ContentfulMemberOnlyMeetingsSection',
    EDUCATIONAL_RESOURCES: 'ContentfulEducationalResourcesSection',
    SUBSCRIBE: 'ContentfulSubscribeSection',
    GLOBAL_NETWORK: 'ContentfulGlobalNetworkSection',
    TESTIMONIALS: 'ContentfulTestimonialsSection',
    MEMBER_REQUIREMENTS: 'ContentfulMemberRequirements',
    CALL_TO_ACTION: 'ContentfulCallForActionSection',
    FOOTER: 'ContentfulFooter',
    SUB_HERO_TEXT: 'ContentfulSubHeroBannerTextSection',
};

export default ContentfulContentSections;
