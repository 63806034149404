import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import Button from 'components/Button';
import IconHamburger from 'components/Icon/Icons/hamburger';
import IconClose from 'components/Icon/Icons/close';
import { ButtonVariants } from 'components/Button/constants';
import { keyCodes } from 'utilities/keyCodes';
import {
    navigationDefaultProps,
    navigationProptypes,
} from './proptypes';
import NavigationItemList from './NavigationList';

const { KEY_ESCAPE, KEY_TAB } = keyCodes;

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.itemRefs = new Map();
    }

    handleToggleKeyDown = (event) => {
        const { isMenuOpen, handleMenuToggle } = this.props;
        const keyCode = event.keyCode || event.which || 0;
        const menuItems = this.itemRefs;
        const lastMenuItem = menuItems.size - 1;

        if (!isMenuOpen) {
            return;
        }


        switch (keyCode) {
        case KEY_ESCAPE:
            event.preventDefault();
            handleMenuToggle();
            break;
        case event.shiftKey && KEY_TAB:
            event.preventDefault();
            menuItems.get(lastMenuItem.toString()).focus();
            break;
        // no default
        }
    };

    render() {
        const {
            callToActionItem,
            menuAlignment,
            handleMenuToggle,
            items,
            isMenuOpen,
            isScrolledToTop,
            title,
            ...other
        } = this.props;

        const navigationClass = classNames('navigation', {
            'navigation--open': isMenuOpen,
            'navigation--scrolled': !isScrolledToTop,
        });

        return (
            <nav role="navigation" className={navigationClass} {...other}>
                <Button
                    id="navigation-toggle"
                    type="button"
                    variant={ButtonVariants.TRANSPARENT}
                    className="navigation__toggle"
                    aria-expanded={isMenuOpen}
                    aria-controls="header-menu"
                    onKeyDown={this.handleToggleKeyDown}
                    onClick={handleMenuToggle}
                >
                    {isMenuOpen ? <IconClose height={16} width={16} /> : <IconHamburger height={13} width={16} />}
                    <span className="visually-hidden">
                        <FormattedMessage id="NAVIGATION_MENU" />
                    </span>
                </Button>
                <NavigationItemList
                    items={items}
                    innerRef={this.itemRefs}
                    menuAlignment={menuAlignment}
                    callToActionItem={callToActionItem}
                    isMenuOpen={isMenuOpen}
                    handleMenuToggle={handleMenuToggle}
                    isScrolledToTop={isScrolledToTop}
                    title={title}
                />
            </nav>
        );
    }
}

Navigation.propTypes = navigationProptypes;
Navigation.defaultProps = navigationDefaultProps;

export default Navigation;
