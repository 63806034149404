import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FormattedMessage, IntlProvider } from 'react-intl';

import '../styles/style.scss';
import HeroBanner from 'containers/HeroBanner';
import ValuePropositionSection from 'containers/ValuePropositionSection';
import MemberMeetingsSection from 'containers/MemberMeetingsSection';
import EducationalResourcesSection from 'containers/EducationalResourcesSection';
import GlobalNetworkSection from 'containers/GlobalNetworkSection';
import TestimonialsSection from 'containers/TestimonialsSection';
import MemberRequirementsSection from 'containers/MemberRequirementsSection';
import CallActionSection from 'containers/CallActionSection';
import HeaderNavigation from 'containers/HeaderNavigation';
import SubscribeSection from 'containers/SubscribeSection';
import SubHeroSection from 'containers/SubHeroSection';
import Footer from 'containers/Footer';
import PageHead from 'containers/PageHead';
import defaultTranslations from 'lang/en.json';
import usersLocale from 'utilities/locale';
import { ContentfulContentSections } from 'utilities/contentfulSections';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/constants';

const RecruitmentMain = ({ data }) => {
    const site = data.contentfulRecruitmentSite.spaces || [];
    const { title } = data.contentfulRecruitmentSite;
    const renderSkipNavigation = () => (
        <div className="skip-navigation">
            <Button
                href="#hero-banner"
                variant={ButtonVariants.SECONDARY}
                className="skip-navigation__link"
                data-mobile="hide"
            >
                <FormattedMessage id="SKIP_NAVIGATION" />
            </Button>
        </div>
    );

    const renderHeader = () => {
        // eslint-disable-next-line no-underscore-dangle
        const getSiteNavigation = site.find(
            (item) =>
                item.__typename === ContentfulContentSections.NAVIGATION_MENU
        );

        return (
            <header className="header">
                <HeaderNavigation
                    title={title}
                    id={getSiteNavigation.id}
                    className="header__navigation header__navigation--fixed"
                />
            </header>
        );
    };

    const renderFooter = () => {
        // eslint-disable-next-line no-underscore-dangle
        const getSiteFooter = site.find(
            (item) => item.__typename === ContentfulContentSections.FOOTER
        );

        return <Footer id={getSiteFooter.id} />;
    };

    const contentSections = site.map((item) => {
        // eslint-disable-next-line no-underscore-dangle
        switch (item.__typename) {
            case ContentfulContentSections.HERO:
                return <HeroBanner key={item.id} id={item.id} />;
            case ContentfulContentSections.SUB_HERO_TEXT:
                return <SubHeroSection key={item.id} id={item.id} />;
            case ContentfulContentSections.VALUE_PROPOSITION:
                return <ValuePropositionSection key={item.id} id={item.id} />;
            case ContentfulContentSections.MEMBER_ONLY_MEETINGS:
                return <MemberMeetingsSection key={item.id} id={item.id} />;
            case ContentfulContentSections.EDUCATIONAL_RESOURCES:
                return (
                    <EducationalResourcesSection key={item.id} id={item.id} />
                );
            case ContentfulContentSections.SUBSCRIBE:
                return <SubscribeSection key={item.id} id={item.id} />;
            case ContentfulContentSections.GLOBAL_NETWORK:
                return <GlobalNetworkSection key={item.id} id={item.id} />;
            case ContentfulContentSections.TESTIMONIALS:
                return <TestimonialsSection key={item.id} id={item.id} />;
            case ContentfulContentSections.MEMBER_REQUIREMENTS:
                return <MemberRequirementsSection key={item.id} id={item.id} />;
            case ContentfulContentSections.CALL_TO_ACTION:
                return <CallActionSection key={item.id} id={item.id} />;
            default:
                return null;
        }
    });

    return (
        <IntlProvider
            locale={usersLocale}
            messages={defaultTranslations}
            textComponent={Fragment}
        >
            <Fragment>
                <PageHead title={title} />
                {renderSkipNavigation()}
                {renderHeader()}
                <main className="wrapper" data-mobile="hide">
                    {contentSections}
                </main>
                {renderFooter()}
            </Fragment>
        </IntlProvider>
    );
};

RecruitmentMain.propTypes = {
    data: PropTypes.shape([]).isRequired,
};

export default RecruitmentMain;

export const pageQuery = graphql`
    query RecruitmentSiteQuery($slug: String!) {
        contentfulRecruitmentSite(slug: { eq: $slug }) {
            title
            spaces {
                ... on ContentfulCallForActionSection {
                    __typename
                    id
                }
                ... on ContentfulEducationalResourcesSection {
                    __typename
                    id
                }
                ... on ContentfulSubscribeSection {
                    __typename
                    id
                }
                ... on ContentfulSubHeroBannerTextSection {
                    __typename
                    id
                }
                ... on ContentfulFooter {
                    __typename
                    id
                }
                ... on ContentfulGlobalNetworkSection {
                    __typename
                    id
                }
                ... on ContentfulHeroSection {
                    __typename
                    id
                }
                ... on ContentfulMemberOnlyMeetingsSection {
                    __typename
                    id
                }
                ... on ContentfulMemberRequirements {
                    __typename
                    id
                }
                ... on ContentfulNavigationMenu {
                    __typename
                    id
                }
                ... on ContentfulTestimonialsSection {
                    __typename
                    id
                }
                ... on ContentfulValuePropositionSection {
                    __typename
                    id
                }
            }
        }
    }
`;
