import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import HeroBannerContent from './HeroBannerContent';

const HeroBanner = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulHeroSection {
                    nodes {
                        id
                        section {
                            imageTextColor
                            imageTextSize
                            textItems {
                                headerText {
                                    headerText
                                }
                                contentText {
                                    raw
                                }
                            }
                            linkItem {
                                text
                                link
                            }
                            image {
                                defaultImage: localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const item = data.allContentfulHeroSection.nodes.find(
                node => node.id === props.id,
            );
            return (
                <HeroBannerContent data={item} />
            );
        }}
    />
);

HeroBanner.propTypes = {
    id: PropTypes.string.isRequired,
};

export default HeroBanner;
