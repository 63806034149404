import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import scrollToElement from 'scroll-to-element';
import IntersectionObserver from 'intersection-observer-polyfill';

import { disableFocusOnItems } from 'utilities/disableItemsFocus';
import { keyCodes } from 'utilities/keyCodes';
import { mediaBreakpoints } from 'utilities/mediaBreakpoints';
import { navigationListItemsProptypes } from './proptypes';
import NavigationCallToActionButton from './NavigationCallToActionButton';

const { KEY_ESCAPE, KEY_TAB } = keyCodes;
const isBrowser = typeof window !== 'undefined';

class NavigationItemList extends Component {
    handleNavigationUpdate = _.debounce((hash, documentTitle) => {
        if (isBrowser) {
            if (window.location.hash !== hash) {
                this.handleRouteUpdate(hash, documentTitle);
                this.getActiveSectionId();
            }
        }
    }, 150);

    componentDidMount() {
        this.handleContentObservation();
        this.getActiveSectionId();
    }

    isDesktopViewport = () =>
        isBrowser ? window.innerWidth >= mediaBreakpoints.tablet : false;

    handleItemRef = (key) => (item) => {
        const { innerRef } = this.props;

        return innerRef.set(key, item);
    };

    handleItemClick = (event, target) => {
        const { handleMenuToggle } = this.props;
        const animationDuration = 600;
        const isDesktopViewport = this.isDesktopViewport();

        event.preventDefault();

        if (isBrowser) {
            scrollToElement(target, {
                offset: -110,
                duration: animationDuration,
                ease: 'in-out-sine',
            });
        }

        if (!isDesktopViewport) {
            handleMenuToggle();
        }
    };

    focusNavigationToggle = () => {
        const toggleNode = document.getElementById('navigation-toggle');

        if (toggleNode) {
            toggleNode.focus();
        }
    };

    handleItemKeyDown = (event) => {
        const { isMenuOpen, innerRef, handleMenuToggle } = this.props;
        const keyCode = event.keyCode || event.which || 0;
        const menuItems = innerRef;
        const lastMenuItem = menuItems.size - 1;
        const isDesktopViewport = this.isDesktopViewport();

        if (isDesktopViewport) {
            disableFocusOnItems(false);
        }

        if (!isMenuOpen) {
            return;
        }

        switch (keyCode) {
            case KEY_ESCAPE:
                event.preventDefault();
                handleMenuToggle();
                break;
            case KEY_TAB:
                if (
                    !isDesktopViewport &&
                    !event.shiftKey &&
                    document.activeElement ===
                        menuItems.get(lastMenuItem.toString())
                ) {
                    event.preventDefault();
                    this.focusNavigationToggle();
                }
                break;
            // no default
        }
    };

    handleGoogleTagManagerDataLayer = (path, title) => {
        if (isBrowser && window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: {
                    path,
                    title,
                },
            });
        }
    };

    getActiveSectionId = () => {
        const { items } = this.props;
        const activeMenuItemClass = 'navigation__item--active';
        const activeMenuItemElement = document.querySelector(
            `.${activeMenuItemClass}`
        );

        if (isBrowser) {
            /* eslint-disable */
            return items.map((item) => {
                const getNavigationItem = document.querySelector(
                    `[href="${item.link}"]`
                );

                if (item.link === window.location.hash) {
                    if (activeMenuItemElement) {
                        activeMenuItemElement.classList.remove(
                            activeMenuItemClass
                        );
                    }
                    getNavigationItem &&
                        getNavigationItem.classList.add(activeMenuItemClass);
                } else if (activeMenuItemElement) {
                    activeMenuItemElement.classList.remove(activeMenuItemClass);
                }
            });
            /* eslint-enable */
        }

        return null;
    };

    handleRouteUpdate = (hash, documentTitle) => {
        if (isBrowser) {
            const url =
                hash === '/' ? `${hash}${window.location.search}` : hash;
            window.history.pushState(
                { currentState: hash },
                documentTitle,
                url
            );
            document.title = documentTitle;
            this.handleGoogleTagManagerDataLayer(
                window.location.href,
                documentTitle
            );
        }
    };

    handleContentObservation = () => {
        const { title } = this.props;
        const defaultDocumentTitle = `${title} - Million Dollar Round Table`;
        let prevScrollYPosition = 0;
        const defaultInersectionRatio = 0.3;

        const handleObservedItems = (sections) => {
            if (sections.length > 0) {
                sections.forEach((section) => {
                    // Section information
                    const sectionId = section.target.getAttribute('id');
                    const sectionIdWithHash = `#${sectionId}`;
                    const sectionTitle =
                        section.target.children[0].children[0].innerText;
                    const isSectionTrackableAsPage =
                        section.target.getAttribute('data-track-as-page') ===
                        'true';
                    const isSectionAnimated =
                        section.target.getAttribute('data-animate') === 'true';
                    const sectionAnimationFinishedClass =
                        'section--animate-end';
                    const isSectionAnimationFinished =
                        section.target.classList.contains(
                            sectionAnimationFinishedClass
                        );

                    // Document information
                    const documentTitle = `${sectionTitle} - Million Dollar Round Table`;

                    // Banner information
                    const bannerAnimationFinishedClass = 'banner--animate-end';
                    const getBanner = document.getElementById('hero-banner');
                    const isBannerAnimated =
                        section.target.getAttribute('data-animate') === 'true';
                    const isBannerAnimationFinished = getBanner
                        ? getBanner.classList.contains(
                              bannerAnimationFinishedClass
                          )
                        : true;

                    // Scrolling information
                    const currentScrollYPosition =
                        section.boundingClientRect.top;
                    const isScrollingDown =
                        prevScrollYPosition <= currentScrollYPosition;
                    const scrollingTopIntersectionRatio =
                        this.isDesktopViewport()
                            ? 0.65
                            : defaultInersectionRatio;

                    const handleSectionAnimations = () => {
                        if (isSectionAnimated && !isSectionAnimationFinished) {
                            section.target.classList.add(
                                sectionAnimationFinishedClass
                            );
                        }
                        if (isBannerAnimated && !isBannerAnimationFinished) {
                            getBanner.classList.add(
                                bannerAnimationFinishedClass
                            );
                        }
                    };

                    if (section.intersectionRatio > defaultInersectionRatio) {
                        handleSectionAnimations();
                    }

                    const handleSectionOnScrollActions = () => {
                        if (isBrowser) {
                            if (window.pageYOffset !== 0) {
                                if (isScrollingDown) {
                                    handleSectionScrollingDown();
                                } else {
                                    handleSectionScrollUp();
                                }
                            } else if (window.location.hash) {
                                this.handleNavigationUpdate(
                                    window.location.pathname,
                                    defaultDocumentTitle
                                );
                            }
                        }
                    };

                    const handleSectionScrollingDown = () => {
                        if (
                            section.intersectionRatio >
                                defaultInersectionRatio &&
                            section.boundingClientRect.top > 0
                        ) {
                            if (isSectionTrackableAsPage) {
                                this.handleNavigationUpdate(
                                    sectionIdWithHash,
                                    documentTitle
                                );
                            }
                        }
                    };

                    const handleSectionScrollUp = () => {
                        if (
                            section.intersectionRatio >
                            scrollingTopIntersectionRatio
                        ) {
                            if (isSectionTrackableAsPage) {
                                this.handleNavigationUpdate(
                                    sectionIdWithHash,
                                    documentTitle
                                );
                            }
                        }
                    };

                    handleSectionOnScrollActions();
                    prevScrollYPosition = currentScrollYPosition;
                });
            }
        };

        const observerThreshold = [
            0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1,
        ];
        const observerOptions = {
            threshold: observerThreshold,
        };

        if (isBrowser) {
            const observer = new IntersectionObserver(
                handleObservedItems,
                observerOptions
            );
            const sections =
                [...document.querySelectorAll('[data-observe="true"]')] || [];
            sections.forEach((section) => observer.observe(section));
        }
    };

    render() {
        const { menuAlignment, items, callToActionItem } = this.props;
        const navigationItemsClass = classNames('navigation__items', {
            [`navigation__items--align-${menuAlignment}`]: true,
        });

        return (
            <div id="header-menu" className="navigation__items-wrapper">
                <ul className={navigationItemsClass}>
                    {items.length > 0 &&
                        items.map((item, index) => {
                            const navigationItemClass = classNames(
                                'navigation__item',
                                {
                                    'navigation__item--highlighted':
                                        item.isCallToAction,
                                }
                            );
                            const itemKey = item.id ? item.id : index;

                            return (
                                <li key={itemKey}>
                                    <a
                                        href={item.link}
                                        className={navigationItemClass}
                                        ref={this.handleItemRef(itemKey)}
                                        onKeyDown={this.handleItemKeyDown}
                                        onClick={(event) =>
                                            this.handleItemClick(
                                                event,
                                                item.link
                                            )
                                        }
                                    >
                                        <span className="navigation__item-content">
                                            {item.title}
                                        </span>
                                    </a>
                                </li>
                            );
                        })}
                </ul>
                <NavigationCallToActionButton
                    link={callToActionItem.link}
                    title={callToActionItem.title}
                    onKeyDown={this.handleItemKeyDown}
                    refToPass={this.handleItemRef(items.length.toString())}
                />
            </div>
        );
    }
}

NavigationItemList.propTypes = navigationListItemsProptypes;

export default NavigationItemList;
