import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldHint = ({
    className,
    id,
    hint,
}) => {
    const hintClass = classNames('field-hint', className);

    return <p id={id} className={hintClass}>{hint}</p>;
};

FieldHint.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FieldHint.defaultProps = {
    className: '',
    hint: '',
};

export default FieldHint;
