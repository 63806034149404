import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { IconCheckmark } from 'components/Icon';

const List = ({
    theme, listTitle, listText, listItems,
}) => {
    const navigationClass = classNames('list', {
        'list--dark': theme === 'dark',
        'list--light': theme === 'light',
    });
    return (
        <section className={navigationClass}>
            {!!listTitle && <h3 className="list__title">{listTitle}</h3>}
            <p className="list__text">{listText}</p>
            <ul className="list__content">
                {listItems.map((item, index) => (
                    <li key={item.id ? item.id : index} className="list__item">
                        <span className="list__icon">
                            <IconCheckmark height={8} width={8} />
                        </span>
                        {item}
                    </li>
                ))}
            </ul>
        </section>
    );
};

List.propTypes = {
    theme: PropTypes.string.isRequired,
    listTitle: PropTypes.string,
    listText: PropTypes.string.isRequired,
    listItems: PropTypes.arrayOf(PropTypes.string),
};

List.defaultProps = {
    listTitle: '',
    listItems: [],
};

export default List;
