import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldInput = ({
    ariaDescribedby,
    className,
    errorMessage,
    id,
    name,
    ...other
}) => {
    const inputClass = classNames('field-input', {
        'field-input--error': !!errorMessage,
    }, className);

    const inputDescriptionOnError = errorMessage ? `${id}-input-error` : '';

    return (
        <Fragment>
            <div className="field-input-wrapper">
                <input id={id} name={name} className={inputClass} {...other} aria-describedby={`${inputDescriptionOnError} ${ariaDescribedby}`} />
                {!!errorMessage && (
                    <span className="field-icon">!</span>
                )}
            </div>
            {!!errorMessage && (
                <p id={`${id}-input-error`} className="field-error">{errorMessage}</p>
            )}
        </Fragment>
    );
};

FieldInput.propTypes = {
    ariaDescribedby: PropTypes.string,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

FieldInput.defaultProps = {
    ariaDescribedby: '',
    className: '',
    errorMessage: '',
};

export default FieldInput;
