import React from "react";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <p className="card__content" key={node.content[0].value}>
                {children}
            </p>
        ),
        [INLINES.HYPERLINK]: (node) => {
            return (
                <a
                    className="card-link"
                    rel="noreferrer"
                    target="_blank"
                    href={node.data.uri}
                >
                    {node.content[0]?.value}
                </a>
            );
        },
    },
};

const plainTextOptions = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => children,
    },
};
export function renderReactComponents(richText) {
    if (richText) {
        return renderRichText(richText, options);
    } else {
        return;
    }
}

export function extractPlainTextFromParagraph(richText) {
    if (richText) {
        return renderRichText(richText, plainTextOptions)[0].join("\n");
    } else {
        return;
    }
}
