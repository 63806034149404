import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import FooterContent from './FooterContent';

const Footer = props => (
    <StaticQuery
        query={graphql`
            query {
                allContentfulFooter {
                    nodes {
                        id
                        phone {
                            itemText
                        }
                        address {
                            itemText
                        }
                        email {
                            itemText
                        }
                        socialLinks {
                            title,
                            link
                        }
                        copyright
                    }
                }
            }            
        `}
        render={({ allContentfulFooter }) => {
            const item = allContentfulFooter.nodes.find(
                node => node.id === props.id,
            );
            return <FooterContent data={item} />;
        }}
    />
);

Footer.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Footer;
