import React from 'react';
import PropTypes from 'prop-types';

import Section from 'components/Section';
import {
    SectionVariants,
    SectionContentAlignment,
    SectionEdgePosition,
} from 'components/Section/constants';
import SignUpForm from 'containers/SignUpForm';


const SubscribeSectionContent = ({ data }) => {
    const {
        textItems, slug,
    } = data.section;

    return (
        <Section
            animate
            id={slug}
            title={textItems.headerText.headerText}
            subtitle={textItems.subheaderText}
            variant={SectionVariants.DEFAULT}
            contentAlignment={SectionContentAlignment.CENTER}
            hasEdge
            edgePosition={SectionEdgePosition.RIGHT}
        >
            <div className="section__content-wrapper">
                <SignUpForm inputId="subscription-email" gtmClassName="subscribe-stay-in-the-know" />
            </div>
        </Section>
    );
};

SubscribeSectionContent.propTypes = {
    data: PropTypes.shape({
        section: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            textItems: PropTypes.shape({
                headerText: PropTypes.shape({
                    headerText: PropTypes.string.isRequired,
                }).isRequired,
                subheaderText: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default SubscribeSectionContent;
