import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { CardVariants } from './constants';

const Card = ({
    author,
    containerElement,
    content,
    icon,
    gatsbyImage,
    imageAltText,
    title,
    variant,
    position,
}) => {
    const cardClass = classNames('card', {
        [`card--${variant}`]: true,
    });

    const renderTitle = () => {
        if (title) {
            return (
                <h3 className="card__title">
                    <ReactMarkdown children={title} />
                </h3>
            );
        }
        return null;
    };

    const renderContent = () => {
        if (content) {
            return typeof content === 'string' ? (
                <p className="card__content">{content}</p>
            ) : (
                content
            );
        }

        return null;
    };

    const renderImage = () => (
        <GatsbyImage
            image={gatsbyImage}
            className="card__image"
            alt={imageAltText || title}
        />
    );

    const standardLayout = (
        <Fragment>
            {!!icon && <div className="card__icon-wrapper">{icon}</div>}
            <div>
                {renderTitle()}
                {renderContent()}
            </div>
        </Fragment>
    );

    const withImageLayout = (
        <Fragment>
            {!!gatsbyImage && renderImage()}
            {renderTitle()}
            {renderContent()}
        </Fragment>
    );

    const withInlineImageLayout = (
        <Fragment>
            {!!gatsbyImage && (
                <div className="card__image-wrapper">{renderImage()}</div>
            )}
            <div className="card__content-wrapper">
                {renderTitle()}
                {renderContent()}
            </div>
        </Fragment>
    );

    const testimonialLayout = (
        <Fragment>
            {!!gatsbyImage && (
                <div className="card__image-wrapper">{renderImage()}</div>
            )}
            <div className="card__content-wrapper">
                {renderContent()}
                {!!author && <p className="card__author">{author}</p>}
                {!!position && (
                    <p className="card__author-position">{position}</p>
                )}
            </div>
        </Fragment>
    );

    let Element = 'div';
    let cardLayoutType;

    if (containerElement) {
        Element = containerElement;
    }

    switch (variant) {
        case CardVariants.WITH_IMAGE:
            cardLayoutType = withImageLayout;
            break;
        case CardVariants.TESTIMONIAL:
            cardLayoutType = testimonialLayout;
            break;
        case CardVariants.WITH_INLINE_IMAGE:
            cardLayoutType = withInlineImageLayout;
            break;
        default:
            cardLayoutType = standardLayout;
    }

    return <Element className={cardClass}>{cardLayoutType}</Element>;
};

Card.propTypes = {
    author: PropTypes.string,
    containerElement: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    icon: PropTypes.node,
    gatsbyImage: PropTypes.object,
    imageAltText: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(CardVariants)),
    position: PropTypes.string,
    title: PropTypes.string,
};

Card.defaultProps = {
    author: '',
    containerElement: '',
    content: '',
    icon: null,
    gatsbyImage: null,
    imageAltText: '',
    variant: CardVariants.STANDARD,
    position: '',
    title: '',
};

export default Card;
